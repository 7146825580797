/*import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function shareUrl(){


    document.addEventListener('DOMContentLoaded', () => {
        const shareData = {
            title: document.title, // Usa o título da página
            url: window.location.href
        };

        const shareButtonIds = {
            'whats-icon-share': `https://wa.me/?text=${encodeURIComponent(shareData.title)}%20${encodeURIComponent(shareData.url)}`,
            'insta-icon-share': `https://www.instagram.com/`, // Redireciona para a página inicial do Instagram
            'linkedin-icon-share': `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareData.url)}&title=${encodeURIComponent(shareData.title)}`,
            'face-icon-share': `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}`,
            'twitter-icon-share': `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareData.url)}&text=${encodeURIComponent(shareData.title)}`,
            'share-copy-share': 'copied'
        };

        Object.keys(shareButtonIds).forEach((id) => {
            
             const itemShare = document.getElementById(id)
             if(!itemShare) return

             itemShare.addEventListener('click', (event) => {
                event.preventDefault();
                const baseUrl = shareButtonIds[id];
                if (baseUrl === 'copied') {
                    navigator.clipboard.writeText(shareData.url).then(() => {
                      
                        Toastify({
                            duration: 3000,
                            position: "center",
                            style: { background: "rgba(var(--alert-success),1)" },
                            text: 'Link Copiado Com Sucesso !!!',
                        }).showToast();
                    });
                } else {
                    // Copia o URL para a área de transferência e depois abre o link em uma nova aba
                    navigator.clipboard.writeText(shareData.url).then(() => {

                            window.open(baseUrl, '_blank'); // Abre o link em uma nova aba
                            // setTimeout(() => {}, 300);
                    });
                }
            });
        });
    });
}*/



import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

export default function shareUrl() {
    document.addEventListener('DOMContentLoaded', () => {
        const shareData = {
            title: document.title, // Usa o título da página
            url: window.location.href
        };

        const shareButtonIds = {
            'whats-icon-share': `https://wa.me/?text=${encodeURIComponent(shareData.title)}%20${encodeURIComponent(shareData.url)}`,
            'insta-icon-share': `https://www.instagram.com/nutriplanoficial/`, // Redireciona para a página inicial do Instagram
            'linkedin-icon-share': `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareData.url)}&title=${encodeURIComponent(shareData.title)}`,
            'face-icon-share': `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}`,
            'youtube-icon-share': `https://www.youtube.com/@Nutriplanoficial`, //https://www.youtube.com/share?url=${encodeURIComponent(shareData.url)}
            'twitter-icon-share': `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareData.url)}&text=${encodeURIComponent(shareData.title)}`,
            'share-copy-share': 'copied'
        };

        Object.keys(shareButtonIds).forEach((id) => {
            const itemShare = document.getElementById(id);
            if (!itemShare) return;

            itemShare.addEventListener('click', (event) => {
                event.preventDefault();
                const baseUrl = shareButtonIds[id];
                if (baseUrl === 'copied') {
                    navigator.clipboard.writeText(shareData.url).then(() => {
                        Toastify({
                            duration: 3000,
                            position: "center",
                            style: { background: "rgba(var(--alert-success),1)" },
                            text: 'Link Copiado Com Sucesso !!!',
                        }).showToast();
                    });
                } else {
                    // Abre o link em uma nova aba
                    window.open(baseUrl, '_blank');
                }
            });
        });
    });
}


