/*import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import axios from 'axios'
import { find } from 'utils/dom'

const form = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const contatoFormSubmit = document.querySelector('#contact-vagas');
    const btnSubmit = document.querySelector("#contact-vagas button[type=submit]");

    if (!contatoFormSubmit) return;

    document.getElementById('email').addEventListener('input', function(e) {
      let value = e.target.value;
      if (value.length > 0) {
        value = value.charAt(0).toLowerCase() + value.slice(1);
      }
      e.target.value = value;
    });

    document.getElementById('telefone').addEventListener('input', function(e) {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 0) {
        value = '(' + value;
      }
      if (value.length > 3) {
        value = value.slice(0, 3) + ') ' + value.slice(3);
      }
      if (value.length > 10) {
        value = value.slice(0, 10) + ' - ' + value.slice(10);
      }
      e.target.value = value;
    });

    contatoFormSubmit.addEventListener('submit', function(event) {
      event.preventDefault();

      disabledBtn(btnSubmit);

      const form_data = new FormData();
      form_data.append('nome', document.querySelector('#contact-vagas input[name=nome]').value);
      form_data.append('email', document.querySelector('#contact-vagas input[name=email]').value);
      form_data.append('telefone', document.querySelector('#contact-vagas input[name=telefone]').value);
      form_data.append('cidade', document.querySelector('#contact-vagas input[name=cidade]').value);
      form_data.append('estado', document.querySelector('#contact-vagas input[name=estado]').value);
      form_data.append('arquivo', document.querySelector('#contact-vagas input[name=arquivo]').files[0]);
      form_data.append('autorizacao_dados', document.querySelector('#contact-vagas input[name=autorizacao_dados]').checked);

      console.log(document.querySelector('#contact-vagas input[name=estado]').value);
      

      const url = "/inscricao-vaga/";
      axios.post(url, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => showMessage(response))
      .catch((error) => showMessage(error.response));

      function showMessage(response) {
        const message = response.data ? response.data.message : response.message;
        showToastify(message);
        contatoFormSubmit.reset();
        setTimeout(() => enabledBtn(btnSubmit), 1000);
      }

      function disabledBtn(btn) {
        btn.disabled = true;
        btn.classList.add("disabled");
      }

      function enabledBtn(btn) {
        btn.disabled = false;
        btn.classList.remove("disabled");
      }
    });
  });
}

export default form;
*/

import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css";
import axios from 'axios';

const form = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const contatoFormSubmit = document.querySelector('#contact-vagas');
    const btnSubmit = document.querySelector("#contact-vagas button[type=submit]");
    const inputArquivo = document.querySelector('#contact-vagas input[name=inputArquivo]');
    const mensagemArquivo = document.getElementById('mensagemArquivo');

    if (!contatoFormSubmit) return;

    // Formata o campo de email
    document.getElementById('email').addEventListener('input', function(e) {
      let value = e.target.value;
      if (value.length > 0) {
        value = value.charAt(0).toLowerCase() + value.slice(1);
      }
      e.target.value = value;
    });

    // Formata o campo de telefone
    document.getElementById('telefone').addEventListener('input', function(e) {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 0) {
        value = '(' + value;
      }
      if (value.length > 3) {
        value = value.slice(0, 3) + ') ' + value.slice(3);
      }
      if (value.length > 10) {
        value = value.slice(0, 10) + ' - ' + value.slice(10);
      }
      e.target.value = value;
    });

    // Função para exibir ou ocultar a mensagem com base no arquivo
    function exibirMensagem() {
      if (inputArquivo && inputArquivo.files.length > 0) {
        mensagemArquivo.classList.remove('hidden');
      } else {
        mensagemArquivo.classList.add('hidden');
      }
    }

    // Adiciona o evento de mudança no input de arquivo
    if (inputArquivo) {
      inputArquivo.addEventListener('change', exibirMensagem);
    }

    contatoFormSubmit.addEventListener('submit', function(event) {
      event.preventDefault();

      if (btnSubmit) {
        disabledBtn(btnSubmit);
      }

      const form_data = new FormData();
      form_data.append('nome', document.querySelector('#contact-vagas input[name=nome]').value);
      form_data.append('email', document.querySelector('#contact-vagas input[name=email]').value);
      form_data.append('telefone', document.querySelector('#contact-vagas input[name=telefone]').value);
      form_data.append('nome_vaga', document.querySelector('#contact-vagas input[name=nome_vaga]').value);
      


      if (inputArquivo && inputArquivo.files.length > 0) {
        form_data.append('arquivo', inputArquivo.files[0]);
      }

      form_data.append('autorizacao_dados', document.querySelector('#contact-vagas input[name=autorizacao_dados]').checked);

      const url = "/inscricao-vaga/";
      axios.post(url, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => showMessage(response))
      .catch((error) => showMessage(error.response));

      function showMessage(response) {
        const message = response.data ? response.data.message : response.message;
        showToastify(message);
        contatoFormSubmit.reset();
        if (btnSubmit) {
          setTimeout(() => enabledBtn(btnSubmit), 1000);
        }
        // Ocultar a mensagem de arquivo após o envio do formulário
        if (mensagemArquivo) {
          mensagemArquivo.classList.add('hidden');
        }
      }

      function disabledBtn(btn) {
        btn.disabled = true;
        btn.classList.add("disabled");
      }

      function enabledBtn(btn) {
        btn.disabled = false;
        btn.classList.remove("disabled");
      }
    });
  });
}

export default form;
