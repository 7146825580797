import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import axios from 'axios'

export default function newsletter(){
    const btnSubmit2 = document.querySelector("#newsletter-form button[type=submit]");
    const newsletter = document.querySelector('#newsletter-form')


    function newsletterForm(){


        function showMessage(response) {
          const message = response.data ? response.data.message : response.message;
          showToastify(message);
          newsletter.reset();
          setTimeout(() =>enabledBtn(btnSubmit2), 1000);
        }
    
        function disabledBtn(btn) {
          btn.disabled = true;
          btn.classList.add("disabled");
        }
    
        function enabledBtn(btn) {
          btn.disabled = false;
          btn.classList.remove("disabled");
        }
    
        newsletter.addEventListener('submit',(e)=>{
            e.preventDefault()
          disabledBtn(btnSubmit2);
    
          const form_data = {
           
            email: document.querySelector('#newsletter-form input[name=email]').value,
       
          };
          const url = "/newsletter/";
          axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
        })
      }
    
      if(newsletter){
        console.log('aaaaaaaa',newsletter)
        newsletterForm()
      }

}