import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const initializeSwiperBlog = () => {
  document.addEventListener('DOMContentLoaded', function () {
    var swiperBlog = new Swiper('.swiperBlog-desktop', {
      slidesPerView: 1,
      spaceBetween: 30,
      autoHeight: true,
      navigation: {
        prevEl: '#container-fora-seta .swiper-button-prev',
        nextEl: '#container-fora-seta .swiper-button-next',
      },
      pagination: {
        el: '.swiperBlog-desktop .swiper-pagination',
        type: 'bullets',
        clickable:true
      },
      breakpoints: {
        // 640: {
        //   slidesPerView: 1,
        //   spaceBetween: 20,
        // },
        // 768: {
        //   slidesPerView: 2,
        //   spaceBetween: 40,
        // },
        // 1024: {
        //   slidesPerView: 3,
        //   spaceBetween: 50,
        // },
      },
    });
    var swiperBlogMobile = new Swiper('.swiperBlog-mobile', {
      slidesPerView: 1.2,
      spaceBetween: 16,
      
      pagination: {
        el: '.swiperBlog-mobile .swiper-pagination',
        type: 'bullets',
        clickable:true
      },
    
    });


   
  });



  
};

export default initializeSwiperBlog;
