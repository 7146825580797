import { gsap } from 'gsap'
import { height } from '../utils/dom'
import { easeDefault } from '../utils/constants'


function animations(attr) {
	const animation = {

		'fade-up': { yPercent: 30, opacity: 0 },
		'fade': { opacity: 0 },
		'fade-up-skew': { yPercent: 30, opacity: 0, skewX: 30, skewY: 5 },
		'fade-down': { yPercent: -30, opacity: 0 },
		'fade-down-skew': { yPercent: -30, opacity: 0 },
		'fade-left': { xPercent: 30, opacity: 0 },
		'fade-right': { xPercent: -10, opacity: 0 },
		'fade-up-right': { yPercent: 30, xPercent: -30, opacity: 0 },
		'fade-up-left': { yPercent: 30, xPercent: 30, opacity: 0 },
		'fade-down-right': { yPercent: -30, xPercent: -30, opacity: 0 },
		'fade-down-left': { yPercent: -30, xPercent: 30, opacity: 0 },
		'fade-zoom-in': { scale: 1.2, opacity: 0 },
		'fade-zoom-out': { scale: 0.8, opacity: 0 },
		'slide-up': { yPercent: -30, opacity: 0 },
		'slide-down': { yPercent: 30, opacity: 0 },
	}
	return animation[attr] || {}
}

/**
 * [data-from]  // objeto contendo o from da animacao
 * [data-to]    // objeto contendo o to da animacao
 * [data-anima] // nome da animacao predefinida na funcao animations
 * [data-timeline] // Se quisermos adicionar um conjunto de animacoes em uma timeline anexada ao window
 **/

export default function animateFrom(elem) {
	const dataFrom = elem.getAttribute('data-from') ? JSON.parse(elem.getAttribute('data-from')) : {}
	const dataTo = elem.getAttribute('data-to') ? JSON.parse(elem.getAttribute('data-to')) : {}
	const dataAnima = elem.getAttribute('data-anima')
	const dataTimeline = elem.getAttribute('data-timeline') || 'tl1'
	const dataLabel = elem.getAttribute('data-label')





	const fromAnimation = animations(dataAnima)

	const from = {
		...fromAnimation,
		...dataFrom,
	}
	const duration = dataTo.duration || 0.7
	const to = {
		opacity: 1,
		xPercent: 0,
		yPercent: 0,
		overwrite: 'auto',
		scale: 1,
		skew: -10,
		rotate: 0,
		duration,
		skewX: 0,
		skewY: 0,
		ease: easeDefault,
		...dataTo,
		onComplete: () => elem.classList.add('animou'),
	}
	// gsap.to(null, {ease:'p'})
	const timelineLabel = dataLabel || '<'

	const timeline = gsap
		.timeline({ delay: 0, overwrite: 'auto' })
		.fromTo(elem, from, to, timelineLabel)


	// .to(elem, { opacity: 1, ease: 'none', duration }, timelineLabel)

	if (!dataTimeline) return

	if (window[dataTimeline]) window[dataTimeline].add(timeline, timelineLabel).timeScale(0.8)
	else window[dataTimeline] = gsap.timeline().add(timeline).timeScale(0.8)
}