/*import Toastify from 'toastify-js'

const showToastify = (message, opts) => {
	Toastify({
		duration: 3000,
		position: "center",
		style: { background: " #AC4C3B" },
		text: message,
		...opts
	}).showToast();
}

export default showToastify*/

import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

const showToastify = (message, opts) => {
	Toastify({
		duration: 3000,
		position: "center",
		//style: { background: "linear-gradient(to right, var(--primary-neutral-), var(--primary-neutral-))" },
		style: { background: "green" },
		text: message,
		...opts
	}).showToast();
};

export default showToastify;