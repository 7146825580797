import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	/** Swiper Inovação | Comunicação | Crescimento */
	const SwiperInovacaoComunicaoCrescimento = new Swiper(".Swiper-Inovacao-Comunicacao-Crescimento", {
		slidesPerView: 5,
		spaceBetween: 0,

	})

	const SwiperInovacao = new Swiper(".SwiperInovacao", {
		slidesPerView: 2.5,
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

	const SwiperComunicacao = new Swiper(".SwiperComunicacao", {
		slidesPerView: "auto",
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

	
    const swiperBanner = new Swiper(".swiperBanner", {
    //   cssMode: true,
      navigation: {
        nextEl: ".swiperBanner .swiper-button-next",
        prevEl: ".swiperBanner .swiper-button-prev",
      },
      pagination: {
        el: ".swiperBanner .swiper-pagination",
      },
			// autoplay: {
			// 	delay: 5000, // Tempo em milissegundos (6 segundos)
			// 	disableOnInteraction: false, // Não interromper o autoplay quando o usuário interagir com o Swiper
			// },
    //   mousewheel: true,
    //   keyboard: true,
    });
  



		
}

