import { gsap } from "gsap";

import { findAll } from "../utils/dom.js";


export default function anchorScroll() {


	findAll("[js-anchor]")
		.forEach((el) => {

			el.addEventListener('click', (e) => {
				e.preventDefault()
				const content = e.currentTarget.getAttribute('js-anchor-to')

				smoothScroll(content)


			})
		})

	function smoothScroll(content) {
		const sectionPX = document.querySelector(content).getBoundingClientRect().top
		const sectionPaddingTop = window.getComputedStyle(document.querySelector(content)).paddingTop

		window.scrollTo({
			top: sectionPX + window.scrollY - parseInt(sectionPaddingTop),
			behavior: 'smooth'
		})
	}

}


