import { find, findAll } from "../utils/dom";
import axios from 'axios'



const PageProdutosFilter = () => {
    const page = find("[js-page='Produtos']");
    if (!page) return;
    const urlParams = new URLSearchParams(window.location.search);
		


		if (urlParams) {
			
			const links = document.querySelectorAll('.pagination a');
			const next = document.querySelector('#next');
			const prev = document.querySelector('#previous');
	
			const filters = {
				marcas: urlParams.getAll('marcas'),
				tipos: urlParams.getAll('tipos'),
				linhas: urlParams.getAll('linhas'),
				modelo: urlParams.getAll('modelo'),
				cores: urlParams.getAll('cores'),
				order: urlParams.getAll('order')
			};
	
			// Função para adicionar filtros aos URLs
			const buildNewURL = (baseURL) => {
					if (!baseURL) return ''; // Verifica se o baseURL existe
	
					let newURL = baseURL.replace('?', '&');
					
					Object.keys(filters).forEach(key => {
							if (filters[key]?.length > 0) {
									newURL += `&${key}=${filters[key].join(`&${key}=`)}`;
							}
					});
	
					return `?${newURL}`;
			};
	
			if (Object.values(filters).some(filter => filter.length > 0)) {
					// Atualizando os links de paginação
					links.forEach(link => {
							const href = link.getAttribute('href');
							if (href) {
									link.href = buildNewURL(href);
							}
					});
	
					// Atualizando os links "next" e "previous"
					if (next && next.getAttribute('href')) next.href = buildNewURL(next.getAttribute('href'));
					if (prev && prev.getAttribute('href')) prev.href = buildNewURL(prev.getAttribute('href'));
			}
	
			// Habilitar paginação visualmente
			const pagination = document.querySelectorAll('.pagination');
			if (pagination) {
					pagination.forEach(pag => {
							pag.style.pointerEvents = 'all';
							pag.style.opacity = 1;
					});
			}
	  }
	


	/********************* */ 
	document.addEventListener('DOMContentLoaded', () => {
		const inputs = document.querySelectorAll('.checkboxes-filter input[type="checkbox"]');
		const limpar_filtro = document.querySelector('#limpar_filtro');
		const dropdownFiltro = document.querySelectorAll('#dropdownMenu a')
		const menuButtonDrop = document.querySelectorAll('#menu-button')
		let queryParams = {};
		let totalCheckedElements = {}; // Object to hold total checked counts

		// pagination 
		let currentPage = 1;
		let totalPages ; 
		const itemsPerPage = 15 ; // Assuming 10 items per page

 

		// showGlobal 

		
		const HTMLPRODUTO = (produto) => `
				<div class="bg-gray-200 p-4"> 
					<a href="/produtos/${produto.slug}">
						<div class="image-container-produtos relative">
							<div class="aspect-custom-1">
								${produto.foto_principal ? `
									<img loading="lazy" class="w-full h-full object-cover" src="${produto.foto_principal}" alt="${produto.nome}">
								` : ''}
							</div>

							${produto.destaque ? `<div class="float-tag destaque">Destaque</div>` : ''}

							${produto.lancamento && !produto.destaque ? `<div class="float-tag lancamento">Lançamento</div>` : ''}

							<div class="icon-container absolute top-2 right-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
									<path d="M21.2573 8.63086H12.4852V0H9.51432V8.63086H0.742188V11.413H9.51432V20H12.4852V11.413H21.2573V8.63086Z" fill="#726045" />
								</svg>
							</div>
						</div>
						<p class="text-headline-5 text-verde-escuro-3 mt-[18px]">${produto.nome}</p>
						${produto.linhas && produto.linhas.length > 0 ? `
							<p class="text-black text-paragraph-7">Linha: <span class="font-bold text-marrom-4">${produto.linhas[0].linha}</span></p>
						` : ''}
						<div class="flex flex-wrap gap-[5px] mt-12">
							${produto.cores && produto.cores.length ? produto.cores?.map(cor => `
								<div class="min-w-20 min-h-20 block rounded-full" title="${cor.cor.nome}" style="background-color: ${cor.cor.cor};"></div>
							`).join('') : ''}
						</div>
					</a>
				</div>
			`;

		function renderProdutos(produtos){
			const produtosContent = find('[js-produtos-content]')
			if(!produtos.length){

				produtosContent.style.visibility = 'visible';
				produtosContent.innerHTML = `
				<div class="flex flex-col md2:items-center">
					<h2 class="mb-12 text-title-12"> Nenhum produto encontrado. </h2>
					<a id="limpar_filtro" href="/produtos"
							class="hidden md2:flex justify-center border border-verde-claro-2 shadow-sm px-8 py-14 bg-white text-verde-escuro-3 hover:bg-gray-50 focus:outline-none gap-16 items-center max-w-[255px] w-full text-headline-4 text-center">Limpar
							filtro <span class="text-marrom-vermelho">X</span> </a>
				</div>
				`	
				return
			}
			const htmlProdutos = produtos.map(produto =>{
				return HTMLPRODUTO(produto)
			})

			produtosContent.style.visibility = 'visible';
			
			produtosContent.innerHTML = htmlProdutos.join('')		
		}


		// QUASE BOA  
		function displayPagination() {
			const paginationDiv = document.getElementById("pagination-front-end");
			paginationDiv.innerHTML = ''; // Limpar paginação anterior
	
			// Criar uma lista não ordenada para a paginação
			const ul = document.createElement('ul');
			ul.classList.add('page-list');
	
			// Se o total de páginas for menor que 6, mostrar todas
			if (totalPages <= 6) {
					for (let i = 1; i <= totalPages; i++) {
							const li = document.createElement('li');
							const a = document.createElement('a');
	
							a.textContent = i;
							a.onclick = () => fetchPage(i);
							a.style.cursor = 'pointer'; // Mudar cursor para pointer para melhor UX
	
							// Destacar a página atual
							if (i === currentPage) {
									li.classList.add('current');
							}
	
							li.appendChild(a);
							ul.appendChild(li);
					}
			} else {
					// Se houver mais de 6 páginas, exibir as três primeiras e as três últimas
					const maxDisplayedPages = 3;
	
					// Exibir as três primeiras páginas
					for (let i = 1; i <= maxDisplayedPages; i++) {
							const li = document.createElement('li');
							const a = document.createElement('a');
	
							a.textContent = i;
							a.onclick = () => fetchPage(i);
							a.style.cursor = 'pointer'; // Mudar cursor para pointer para melhor UX
	
							// Destacar a página atual
							if (i === currentPage) {
									li.classList.add('current');
							}
	
							li.appendChild(a);
							ul.appendChild(li);
					}
	
					// Exibir '...' se a página atual for maior que 4
					if (currentPage > maxDisplayedPages + 1) {
							const ellipsis = document.createElement('li');
							ellipsis.textContent = '...';
							ul.appendChild(ellipsis);
					}
	
					// Exibir páginas em torno da página atual
					const startPage = Math.max(currentPage - 1, maxDisplayedPages + 1);
					const endPage = Math.min(currentPage + 1, totalPages - maxDisplayedPages);
	
					for (let i = startPage; i <= endPage; i++) {
							const li = document.createElement('li');
							const a = document.createElement('a');
	
							a.textContent = i;
							a.onclick = () => fetchPage(i);
							a.style.cursor = 'pointer'; // Mudar cursor para pointer para melhor UX
	
							// Destacar a página atual
							if (i === currentPage) {
									li.classList.add('current');
							}
	
							li.appendChild(a);
							ul.appendChild(li);
					}
	
					// Exibir '...' se a página atual for menor que totalPages - 3
					if (currentPage < totalPages - maxDisplayedPages) {
							const ellipsis = document.createElement('li');
							ellipsis.textContent = '...';
							ul.appendChild(ellipsis);
					}
	
					// Exibir as três últimas páginas
					for (let i = totalPages - maxDisplayedPages + 1; i <= totalPages; i++) {
							const li = document.createElement('li');
							const a = document.createElement('a');
	
							a.textContent = i;
							a.onclick = () => fetchPage(i);
							a.style.cursor = 'pointer'; // Mudar cursor para pointer para melhor UX
	
							// Destacar a página atual
							if (i === currentPage) {
									li.classList.add('current');
							}
	
							li.appendChild(a);
							ul.appendChild(li);
					}
			}
	
			paginationDiv.appendChild(ul); // Anexar a lista ao paginationDiv
	}


	

		function fetchPage(page) {

			const queryString = Object.keys(queryParams)
			.filter(key => queryParams[key].length > 0 && key !== 'page')
			.map(key => `${key}=${queryParams[key].join(',')}`)
			.join('&');

		

			const urlToFetch = `?page=${page}&${queryString}`

			history.pushState(null, '', urlToFetch);

		
			currentPage = page;
			fetchProdutos(urlToFetch);
		}


		function renderDropdownOptions(type, items, key) {
			const dropdown = document.querySelector(`.dropdown-${type}`);
			items.forEach(item => {
					const checkboxHTML = `
							<div class="mb-10">
									<label class="block mb-2 text-marrom-4 text-[#3A3D29] flex gap-[4px] items-center">
											<input type="checkbox" name="${type}" value="${item.id}" class="mr-2 accent-marrom-vermelho"> ${item[key]}
									</label>
							</div>`;
					dropdown.innerHTML += checkboxHTML;
			});

	}

		function renderFilter(data){
			 // Clear previous options
			
			 document.querySelector('.dropdown-tipos').innerHTML = '';
			 document.querySelector('.dropdown-linhas').innerHTML = '';
			 document.querySelector('.dropdown-modelos').innerHTML = '';
			 document.querySelector('.dropdown-cores').innerHTML = '';
	 
			 // Render new options
			 renderDropdownOptions('tipos', data.tipos, 'tipo');
			 renderDropdownOptions('linhas', data.linhas, 'linha');
			 renderDropdownOptions('modelos', data.modelos, 'modelo');
			 renderDropdownOptions('cores', data.cores, 'nome');

			 setCheckboxes();
		}


		function filterMarcas(){
			const idsMarcas = queryParams['marcas'] ? queryParams['marcas'].join(',') : ''

			 console.log('.')
			const  filterUrl = `/filtros_marca/?marca=${idsMarcas}`
			const jsFilterMarcas = document.querySelector('[js-filtros-marcas]')
			jsFilterMarcas.style.opacity = '0.5';
			jsFilterMarcas.style.pointerEvents = 'none';

			axios.get(filterUrl)
			.then((response) =>{
				if(response.data){
					renderFilter(response.data)
					jsFilterMarcas.style.opacity = '1';
					jsFilterMarcas.style.pointerEvents = 'all';
					console.log('before',queryParams )
				}
			})
			.catch( (error) =>{
				console.log('Erro na requisição:', error);
			})
		}

		let currentController = null;
		let totalMarcas;
		function fetchProdutos(params='') {
			// Aborta a requisição anterior se existir
			if (currentController) {
					currentController.abort();
			}
	
			// Cria um novo AbortController para a nova requisição
			currentController = new AbortController();
			const signal = currentController.signal;
	
			const url = `/produtos_api/${params}`;
			
			if (Object.keys(queryParams).length !== 0) {				
				limpar_filtro.style.display = 'block';
			}

			document.getElementById('loader').style.display = 'block';
			const produtosContent = document.querySelector('[js-produtos-content]')
			
			// .innerHTML = '';
			produtosContent.style.visibility = 'hidden';


			const marcarsLength = queryParams['marcas']
			console.log('length',marcarsLength)
			if(marcarsLength && marcarsLength.length !== totalMarcas){
				totalMarcas = marcarsLength.length
				filterMarcas()
				console.log('filtrou')
			}
			
				axios.get(url, { signal })
				.then((response) => {
					totalPages = Math.ceil(response.data.count / itemsPerPage);

							// filterMarcas()

					document.getElementById('loader').style.display = 'none';
						if (response.data.results) {
								renderProdutos(response.data.results);
						}
						// Display pagination
  					displayPagination();
						
				})
				.catch((error) => {
					document.getElementById('loader').style.display = 'none';

					const produtosContent = find('[js-produtos-content]')						
					produtosContent.innerHTML = `
						<div class="flex flex-col md2:items-center" style="grid-column: 1/3">
							<h2 class="mb-12 text-title-12"> Erro na requisição. Por favor, tente novamente ou limpe os filtros.</h2>
							<a id="limpar_filtro" href="/produtos"
									class="hidden md2:flex justify-center border border-verde-claro-2 shadow-sm px-8 py-14 bg-white text-verde-escuro-3 hover:bg-gray-50 focus:outline-none gap-16 items-center max-w-[255px] w-full text-headline-4 text-center">Limpar
									filtro <span class="text-marrom-vermelho">X</span> </a>
						</div>
						`	
							
						

						if (axios.isCancel(error)) {
								console.log('Requisição abortada:', error.message);
						} else {
								console.log('Erro na requisição:', error);

						}
				});
			
	  }
	




		function handleDropdownChange(newUrl) {
			dropdownFiltro.forEach(i => {
					let order = i.getAttribute('data-order'); // Valor do atributo 'data-order'
					let href = newUrl; // Usa newUrl como base
	
					// Verifica se a URL já contém o parâmetro 'order'
					if (href.includes('order=')) {
							// Usa uma expressão regular para substituir o valor de 'order' na URL
							href = href.replace(/(order=)[^&]*/, `$1${order}`);
					} else {
							// Se não houver o parâmetro 'order', adiciona ao final da URL
							let separator = href.includes('?') ? '&' : '?';
							href = `${href}${separator}order=${order}`;
					}
	
					// Atualiza o href com o novo valor de 'order'
					i.href = href;
					
					
			});
		} 

		function handleInputChange( e){
			const item = e.target.value;
			const name = e.target.name;
			
			if(name === 'marcas'){
				// Manter apenas 'marcas'
				
				for (let key in queryParams) {
					if (key !== "marcas") {
							delete queryParams[key];
					}
				}
			}
			if (!queryParams[name]) {
					queryParams[name] = [];
			}

			if (e.target.checked) {
					if (!queryParams[name].includes(item)) {
							queryParams[name].push(item);
					}
			} else {
					queryParams[name] = queryParams[name].filter(val => val !== item);
			}

			queryParams['page'] = [1];
			currentPage = 1
			delete queryParams['q'];

		
			const queryString = Object.keys(queryParams)
					.filter(key => queryParams[key].length > 0)
					.map(key => `${key}=${queryParams[key].join(',')}`)
					.join('&');

			/*
			window.location = `?${queryString}`;
			*/
			 // Atualiza a URL sem recarregar a página
			const newUrl = `?${queryString}`;
			history.pushState(null, '', newUrl);
			const closeModal = find('#fechar-filtros')

			updateTotalChecked(); // Update total checked on change
			handleDropdownChange(newUrl)
			fetchProdutos(newUrl)


			closeModal.click()
		}

		function scrollToTopResults(timeout= 0, behavior='smooth'){
			const resultSection = document.querySelector('#produto-resultados');
						const elementTop = resultSection.getBoundingClientRect().top + window.pageYOffset;

						setTimeout(() => {
								window.scrollTo({
										top: elementTop - 200,
										behavior: behavior  
								});
						}, timeout);
		}



		document.querySelector('[js-filtros-marcas]')?.addEventListener('change', function (e) {
			// Check if the clicked element is a checkbox
			if (e.target && e.target.type === 'checkbox') {
					handleInputChange(e);  // Handle the checkbox change

					scrollToTopResults()
			}
	});


		function getQueryParams() {
				const params = new URLSearchParams(window.location.search);
				params.forEach((value, key) => {
						queryParams[key] = value.split(',');
				});

				if (Object.keys(queryParams).length) {
						limpar_filtro.style.display = 'block';
						scrollToTopResults(80, 'instant')
				}
		}

		function setCheckboxes() {
			
			const inputs = document.querySelectorAll('.checkboxes-filter input[type="checkbox"]');
				inputs.forEach(input => {
					
						const name = input.name;
						const value = input.value;
						if (queryParams[name] && queryParams[name].includes(value)) {
								input.checked = true;
						}else{
							input.checked = false;
						}
				});

				updateTotalChecked(); // Update totals when checkboxes are set
				updateButtonStates()
		}

		function updateTotalChecked() {
				// Reset the total checked counts
				totalCheckedElements['marcas'] = 0;
				totalCheckedElements['tipos'] = 0;
				totalCheckedElements['linhas'] = 0;
				totalCheckedElements['modelos'] = 0;
				totalCheckedElements['cores'] = 0;
				const totalCheckedMobile = document.querySelector('[total-filtered]')
				const inputsChecked = document.querySelectorAll('.checkboxes-filter input[type="checkbox"]:checked');
				totalCheckedMobile.innerHTML = inputsChecked.length
				
				
			
				const inputs = document.querySelectorAll('.checkboxes-filter input[type="checkbox"]');
				inputs.forEach(input => {
						const name = input.name;
						if (input.checked) {
								totalCheckedElements[name]++;
						}
				});

				// Display the total counts
				const totalDisplayElements = document.querySelectorAll('.total-checked');
				totalDisplayElements.forEach(el => {
						const filterName = el.dataset.filter; // Assuming each element has a data-filter attribute
						if (totalCheckedElements[filterName]) {
								el.textContent = `${totalCheckedElements[filterName]}`;
								el.classList.add('active')
						} else {
							el.classList.remove('active')
								el.textContent = `0`; // Default to 0 if none checked
						}
				});
		}
 
		getQueryParams();
		filterMarcas();
	
		
		const queryString = Object.keys(queryParams)
		.filter(key => queryParams[key].length > 0)
		.map(key => `${key}=${queryParams[key].join(',')}`)
		.join('&');


		
		
		currentPage =  queryParams.page && Object.keys(queryParams.page).length ? +queryParams.page[0] : 1
		
		fetchProdutos('?' + queryString)
		handleDropdownChange('?' + queryString)
		const abrirFiltro = document.querySelector('#abrir-filtros');
		const fechar = document.querySelector('#fechar-filtros');
		const next = document.querySelector('#next');
		const prev = document.querySelector('#previous');



		// Função para atualizar o estado dos botões
		function updateButtonStates() {
			
			if (currentPage <= 1) {
					prev.classList.add('disable'); // Desabilita o botão de "anterior"
			} else {
					prev.classList.remove('disable'); // Habilita o botão de "anterior"
			}

			if (currentPage >= totalPages) {
					next.classList.add('disable'); // Desabilita o botão de "próximo"
			} else {
					next.classList.remove('disable'); // Habilita o botão de "próximo"
			}
		}

		updateButtonStates()


		limpar_filtro.addEventListener('click', async function () {
			
		
			// Define a URL base, sem parâmetros de consulta
			const baseUrl = window.location.origin + window.location.pathname;

			limpar_filtro.style.display = 'none';
			menuButtonDrop.innerHTML = ` <span class="text-headline-4">Ordenar por:</span>`
			// Usa pushState para atualizar a URL sem parâmetros de consulta
			history.pushState(null, '', baseUrl);
			
			queryParams = {};
			currentPage = 1;

			setCheckboxes();
			fetchProdutos();
		});

		const paginationWrapper = document.querySelector('#pagination-front-end')
		paginationWrapper.addEventListener('click', function(e){
				const target = e.target.closest('li:not(.current)')

				if(!target) return

				scrollToTopResults(0)
				updateButtonStates()
		})
		
		abrirFiltro.addEventListener('click', function () {
				const filter = document.querySelector('.checkboxes-filter');
				filter.classList.add('active');
		});

		fechar.addEventListener('click', function () {
				const filter = document.querySelector('.checkboxes-filter');
				filter.classList.remove('active');
		});

			next.addEventListener('click', function () {
				scrollToTopResults(0)
				if (currentPage < totalPages) { // Verifica se a próxima página existe
						currentPage++; // Incrementa a página atual
						fetchPage(currentPage); // Busca a próxima página
						updateButtonStates(); // Atualiza o estado dos botões
				}
		});
		
		prev.addEventListener('click', function () {
			scrollToTopResults(0)
				if (currentPage > 1) { // Verifica se a página anterior existe
						currentPage--; // Decrementa a página atual
						fetchPage(currentPage); // Busca a página anterior
						updateButtonStates(); // Atualiza o estado dos botões
				}
		});
		

});

}

export default PageProdutosFilter;
