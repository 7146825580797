
import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import axios from 'axios'
import { find } from 'utils/dom'
const form = () => {
document.addEventListener('DOMContentLoaded', function() {
  const contatoFormSubmit = document.querySelector('#enter-in-contact');
  const btnSubmit = document.querySelector("#enter-in-contact button[type=submit]");


  if(!contatoFormSubmit)return
  document.getElementById('email').addEventListener('input', function(e) {
    let value = e.target.value;
    if (value.length > 0) {
      value = value.charAt(0).toLowerCase() + value.slice(1);
    }
    e.target.value = value;
  });

  document.getElementById('telefone').addEventListener('input', function(e) {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 0) {
      value = '(' + value;
    }
    if (value.length > 3) {
      value = value.slice(0, 3) + ') ' + value.slice(3);
    }
    if (value.length > 10) {
      value = value.slice(0, 10) + ' - ' + value.slice(10);
    }
    e.target.value = value;
  });

  if (contatoFormSubmit) {
    contatoFormSubmit.addEventListener('submit', function(event) {
      event.preventDefault();

      disabledBtn(btnSubmit);

      const form_data = {
        nome: document.querySelector('#enter-in-contact input[name=nome]').value,
        email: document.querySelector('#enter-in-contact input[name=email]').value,
        telefone: document.querySelector('#enter-in-contact input[name=telefone]').value,
        cidade: document.querySelector('#enter-in-contact input[name=cidade]').value,
        estado: document.querySelector('#enter-in-contact input[name=estado]').value,
        mensagem: document.querySelector('#enter-in-contact textarea[name=mensagem]').value
      };

      const url = "/fale-conosco/";
      axios.post(url, form_data)
        .then((response) => showMessage(response))
        .catch((response) => showMessage(response));

      function showMessage(response) {
        const message = response.data ? response.data.message : response.message;
        showToastify(message);
        contatoFormSubmit.reset();
        setTimeout(() =>enabledBtn(btnSubmit), 1000);
      }

      function disabledBtn(btn) {
        btn.disabled = true;
        btn.classList.add("disabled");
      }

      function enabledBtn(btn) {
        btn.disabled = false;
        btn.classList.remove("disabled");
      }
    });
  }



});
}

export default form;