import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import editorJS from "./modules/editorJS";
import produtosFilters from "./modules/produtosFilters";
/*import newsletter from "./modules/contato"*/
import contatoJs from './modules/formularioContato';
import formularioVagas from './modules/formularioVagas';
import swiperBlog from './modules/swiperBlog';
import redesSociais from './modules/redesSociais';
/*import timeLineEsg from './modules/timeLineEsg';*/
/*import swiperSingle from './modules/swiperSingle';*/
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import custonAnimation from "./modules/custon-animation"

import richTextBannerHome from "./modules/richTextBannerHome"

// NOVOS

import anchorScroll from './animation/anchorScroll'
import initializeSwiperSingle from './modules/swiperSingle';
import newsletter from './modules/newsletter';
import SingleProdutos from './pages/SingleProdutos';
import NovoEditorJs from './modules/novoEditorJs';
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
editorJS()
animateSetup()
animaAoScroll()

// NOVOS
anchorScroll()
swiperBlog()
initializeSwiperSingle()
redesSociais()
produtosFilters()
//timeLineEsg()
//swiperSingle()

// Pages
PageHome()
PageLogin()?.init()
SingleProdutos()

// utils logs
logs()
newsletter()
contatoJs()
formularioVagas()
NovoEditorJs()
/*newsletter()*/

richTextBannerHome()

document
	.addEventListener("DOMContentLoaded", () =>
		custonAnimation()
	)


const itensMenu = document.querySelectorAll("#menuMobile li a")
const links = document.querySelectorAll(".links-menu-mobile")

if(itensMenu){
	itensMenu.forEach(i => {
		i.addEventListener("click",() =>{
		 const btn =	document.querySelector("[data-xclose] ")
		 if(i.classList.contains('has-children')){
			return
		 }
		 btn.click()
		 console.log(btn)
		})
	})
}