import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animationParalax from "./animationParallax";
import { animationChar } from "./animationChar";
import splitType from './splitType'
import { findAll } from "../utils/dom";

gsap.registerPlugin(ScrollTrigger);

// funcao que adiciona paralax apos animar e aparecer na tela
function animationMouseMove() {
	const els = document.querySelectorAll('[js-parallax]')
	console.log(els)
	if (!els.length) return

	els.forEach(el => {

		const callback = (mutationList, observer) => {
			for (const mutation of mutationList) {
				const valid = mutation.type === "attributes"
					&& mutation.attributeName === 'class'
					&& el.classList.contains('animou')
				if (valid) {
					animationParalax(el)
					observer.disconnect()
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(el, { attributes: true, childList: true, subtree: true });

	})



}

function rotateInfinite() {
	const els = findAll('[js-rotate]')
	els.forEach(el => {
		gsap.to(el, {
			rotate: 360,
			duration: 1,
			repeat: -1,
			ease: 'none'
		})
	})
}

function logoAnimation() {
	const els = findAll('[js-gsap-logo]')
	gsap.set(els, { opacity: 0 })
	gsap.to(els, {
		opacity: 1,
		stagger: {
			grid: "auto",
			from: "end",
			axis: "y",
			amount: 1
		}
	})


}

export default function () {
	gsap.registerPlugin(ScrollTrigger);
	logoAnimation()
	animationMouseMove()
	splitType()
	animationChar()
	rotateInfinite()
}