export default function startTabs(Items, firstItemActive, display){
  if (Items && firstItemActive) {
      // Set the initial state to show the first group
      const initialGroup = firstItemActive.querySelector('[data-format]');
      const firstAbove = document.querySelector('[js-filter-links] [data-format]');
      if(firstAbove){
          firstAbove.classList.add('active')
      }
      if (initialGroup) {
          initialGroup.style.display = display;
      }

      Items.forEach(item => {
          item.addEventListener('click', (e) => {
              // Remove active class from all items
              Items.forEach(i => i.classList.remove('active'));
              // Add active class to the clicked item
              e.currentTarget.classList.add('active');

              const selectedFormat = e.currentTarget.getAttribute('data-format');
              const filterGroups = firstItemActive.querySelectorAll('[data-format]');

              filterGroups.forEach(group => {

                  if (group.getAttribute('data-format') === selectedFormat) {
                      group.style.display = display;  // Show the group
                  } else {
                      group.style.display = 'none';  // Hide the group
                  }
              });
          });
      });
  }
}

// export default function startTabs(Items, firstItemActive, display) {
//     if (Items && firstItemActive) {
//         // Set the initial state to show the first group and the first color as active
//         const initialGroup = firstItemActive.querySelector('[data-format]');
//         const firstAbove = document.querySelector('[js-filter-links] [data-format]');
//         const firstColor = document.querySelector('[data-color]');

//         if (firstAbove) {
//             firstAbove.classList.add('active');
//         }
//         if (initialGroup) {
//             initialGroup.style.display = display;
//         }
//         if (firstColor) {
//             firstColor.classList.add('active');
//         }

//         // Add click event for items (original logic)
//         Items.forEach(item => {
//             item.addEventListener('click', (e) => {
//                 // Remove active class from all items
//                 Items.forEach(i => i.classList.remove('active'));
//                 // Add active class to the clicked item
//                 e.currentTarget.classList.add('active');

//                 const selectedFormat = e.currentTarget.getAttribute('data-format');
//                 const filterGroups = firstItemActive.querySelectorAll('[data-format]');

//                 filterGroups.forEach(group => {
//                     if (group.getAttribute('data-format') === selectedFormat) {
//                         group.style.display = display;  // Show the group
//                     } else {
//                         group.style.display = 'none';  // Hide the group
//                     }
//                 });
//             });
//         });

//         // Add click event for color items
//         const colorItems = document.querySelectorAll('[data-color]');
//         colorItems.forEach(colorItem => {
//             colorItem.addEventListener('click', (e) => {
//                 // Remove active class from all color items
//                 colorItems.forEach(item => item.classList.remove('active'));
//                 // Add active class to the clicked color item
//                 e.currentTarget.classList.add('active');

//                 // Get the color data attribute
//                 const colorId = e.currentTarget.getAttribute('data-color');
//                 console.log('Color ID:', colorId);  // Use this value as needed
//             });
//         });
//     }
// }