import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
const initializeSwiperSingle = () =>{
    document.addEventListener("DOMContentLoaded", function () {

        const lupa = document.querySelector('#lupa-icon-js')

        // Inicializar Swiper
    
          const swiperSinglePequeno = new Swiper('.swiper.single-thumbs', {
       
                spaceBetween: 12,  // Espaçamento entre slides pequenos
                //   slidesPerView:'auto',  
                slidesPerView: 4,
                freeMode: true,
                breakpoints:{
                    768:{
                        slidesPerView: 5,
                    }
                },
                watchSlidesProgress: true,
                        navigation: {
                            nextEl: '.single-thumbs .swiper-button-next', 
                            prevEl: '.single-thumbs .swiper-button-prev',  
                        },
          
        });
    
        const swiperSingle = new Swiper('.swiperPrincipal', {
            spaceBetween: 10,
            // navigation: {
            //   nextEl: '.swiper-button-next-single-produto', 
            //   prevEl: '.swiper-button-prev-single-produto',  
            // },
            thumbs: {
             swiper: swiperSinglePequeno,  
            },
        });

        window.swiperSingle = swiperSingle


        lupa && lupa.addEventListener('click', ()=>{
            const currentSlide = swiperSingle.slides[swiperSingle.activeIndex]
            if(!currentSlide)return
            const imgUrl = currentSlide.querySelector('img').src

                

            new Fancybox(
                [
                  {
                    src: imgUrl, // Replace with the path to your image
                    type: "image",
                  },
                ],
                {
                  // Your custom options
                  // For example, you can customize the animation, buttons, etc.
                  loop: true, // Allow looping through images
                  toolbar: true, // Show toolbar
                  buttons: ["zoom", "fullScreen", "close"], // Buttons to show in the Fancybox
                }
              );
        })

    });
}


export default initializeSwiperSingle;